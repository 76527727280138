//MODULES
import React from 'react';

//COMPONENTS


const PageNotFound = () => (
    <div>
        Uh oh! That page doesn't seem to exist. 
    </div>
);

export default PageNotFound;