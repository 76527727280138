// MODULES
import React, { Component } from 'react';
// COMPONENTS
import CurrentWeekStats from './stats/current-week-stats/CurrentWeekStats';
// CSS
import '../App.css';
// CUSTOM FILES
import { currentWeekNumber } from '../utils/getWeek';
import { listRecords } from '../api/airtableAPI';

// let curentWeek = weekNumber(new Date());

class Stats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cashBalance: 0,
      rbmcBalance: 0
    }
  }

  componentDidMount() {
    listRecords().firstPage((err, records) => {
      if (err) { return }

      let cashBalance = 0;
      let rbmcBalance = 0;

      records.forEach((record) => {
        cashBalance += record.get('Cash Balance');
        rbmcBalance += record.get('MC/RB Balance');
      });

      this.setState({ 
        cashBalance,
        rbmcBalance 
      });
    });

    // TODO: 
    // 1. Add date picker
    // 2. Get date and use below
    // 3. Pass date to airtable api for processing
    // let dateObject = date.parse('2015/01/02', 'YYYY/MM/DD');
    // let dayOfTheWeek = date.format(dateObject, 'dddd');
    // console.log(dayOfTheWeek);
  }

  render() {
    return (
      <div className="app-section-wrapper">
        <CurrentWeekStats 
          weekNumber={ currentWeekNumber }
          cashBalance={ this.state.cashBalance }
          rbmcBalance={ this.state.rbmcBalance }
        />
      </div>
    );
  }
};

export default Stats;