//MODULES
import React from 'react';
import Modal from 'react-modal';
// CUSTOM FILES
// COMPONENTS
import PointsList from './dashboard-list-view/points-list/PointsList';
import PointsListFilter from './dashboard-list-view/points-list/PointsListFilter';
import AddPointPage from '../components/dashboard-list-view/add-point-form/AddPointPage';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
}

class DashboardListView extends React.Component {
    constructor() {
        super();

        this.state = {
            modalIsOpen: false,
            isHidden: true
        };

        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    openModal() {
        this.setState({modalIsOpen: true});
    }

    afterOpenModal() {
        // references are now sync'd and can be accessed.
        // this.subtitle.style.color = '#f00';
    }

    closeModal() {
        this.setState({modalIsOpen: false});
    }

    handleModal = () => {
        this.setState({ isHidden: !this.state.isHidden });
    }

    render() {
        return (
            <div className="app-section-wrapper">
                <div className="dashboard__header__title">
                    Cadence's Points List
                </div>

                <button className="add-point-modal__open" onClick={ this.openModal }>+</button>
                
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <button className="add-point-modal__close" onClick={ this.closeModal }>x</button>
                    <AddPointPage />
                </Modal>

                <PointsListFilter />

                <div className="dashboard-list-view__header">
                    <div className="dashboard-list-view__header__description">Chore</div>
                    <div className="dashboard-list-view__header__date">Date Created</div>
                    <div className="dashboard-list-view__header__options">Options</div>
                </div>
                <PointsList />
            </div>
        )
    };
};

export default DashboardListView;


// <div className={ this.state.isHidden ? "modal-hidden" : "modal-visible" }>
//     <button onClick={ this.handleModal }>x</button>
//     <AddPointPage />
// </div>