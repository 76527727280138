import moment from 'moment';

// GET POINTS
export default (points, { text, sortBy, startDate, endDate })  => {
    return points.filter((point) => {
        const createdAtMoment = moment(point.createdAt)
        const startDateMatch = startDate ? startDate.isSameOrBefore(createdAtMoment, 'day') : true;
        const endDateMatch = endDate ? endDate.isSameOrAfter(createdAtMoment, 'day') : true;
        let textMatch = point.note.toLowerCase();
        if (text && point.note) {
            textMatch = point.note.toLowerCase().includes(text.toLowerCase());
        }
    
        return startDateMatch && endDateMatch && textMatch;
    }).sort((a, b) => {
        // TODO: Add sorting by chore
        return a.createdAt < b.createdAt ? 1 : -1;
    });
};