import React from 'react';
import { connect } from 'react-redux';
import { startAddChore, startEditChore } from '../../redux/actions/chores';
// COMPONENTS
import AddChoreForm from './AddChoreForm';

const AddPointPage = (props) => {
    return (
        <div className="app-section-wrapper">
            <div className="add-point-form">
                <h3>Add Chore Form</h3>
                <AddChoreForm 
                    id={ props.id }
                    name={ props.name }
                    add={ props.add }
                    onSubmit={(chore, add) => {
                        console.log('props: ', props)
                        console.log('ID: ', props.id)
                        if (add) {
                            props.dispatch(startAddChore(chore));
                            console.log('this fired')
                        } else {
                            props.dispatch(startEditChore(props.id, chore));
                        }
                    }}
                />
            </div>
        </div>
    )
};

export default connect()(AddPointPage);