//MODULES
import React from 'react';
//COMPONENTS
import HeaderDashboardWeekOf from './HeaderDashboardWeekOf';
import HeaderDashboardWeek from './HeaderDashboardWeek';

const HeaderDashboard = (props) => { 
    return (
    <div className="dashboard__header">
        <HeaderDashboardWeekOf />
        <HeaderDashboardWeek />
    </div>
)};

export default HeaderDashboard;