// import { chores } from '../fixtures/test-data';

const choresReducerDefaultState = [];

export default (state = choresReducerDefaultState, action) => {
    switch (action.type) {
        case 'ADD_CHORE':
            return [
                ...state, 
                action.chore
            ];
        case 'DELETE_CHORE':
            return state.filter((chore) => chore.id !== action.id);
        case 'EDIT_CHORE':
            return state.map((chore) => {
                if (chore.id === action.id) {
                    return {
                        ...chore,
                        ...action.updates
                    }
                } else {
                    return chore
                }
            });
        case 'SET_CHORES':
            return action.chores;
        default:
            return state;
    }
};