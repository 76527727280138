// MODULES
import React from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
// CUSTOM FILES
// import { chores } from '../../redux/fixtures/test-data';
// COMPONENTS
import ChoresSettingsItem from './ChoresSettingsItem';
import AddChorePage from './AddChorePage';


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
}

class ChoresSettingsList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modalIsOpen: false,
            isHidden: true, 
            id: ''
        };

        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    openModal(id) {
        this.setState({modalIsOpen: true});
        console.log(id)
    }

    afterOpenModal() {
        // references are now sync'd and can be accessed.
        // this.subtitle.style.color = '#f00';
    }

    closeModal() {
        this.setState({modalIsOpen: false});
    }

    handleModal = () => {
        this.setState({ isHidden: !this.state.isHidden });
    }

    render() {
        return (
            <div>
                {
                    this.props.chores.map((chore) => {
                        return <ChoresSettingsItem key={ chore.name } chore={ chore } dispatch={ this.props.dispatch } />
                    })
                }

                <button onClick={ this.openModal }>Add Chore</button>
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <button className="add-point-modal__close" onClick={ this.closeModal }>x</button>
                    <AddChorePage add={ true }/>
                </Modal>
            </div>
        )
    }
};

const mapStateToProps = (state) => {
    return {
        chores: state.chores
    };
};

export default connect(mapStateToProps)(ChoresSettingsList);