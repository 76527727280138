//MODULES
import React from 'react';
import moment from 'moment';
// CUSTOM FILES
import { connect } from 'react-redux';
import selectPoints from '../../redux/selectors/selectPoints';
import { startAddPoint, startDeletePoint } from '../../redux/actions/points';
// STYLE
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

class ChorePoint extends React.Component {
    state = {
        id: 0,
        chore: this.props.chore.split(' ').join('').toLowerCase(),
        note: this.props.chore,
        isDone: false,
        createdAt: this.props.day
    }

    componentDidMount() {
        const matchedPoint = this.props.points.filter((point) => {
            return point.note === this.state.note && moment(point.createdAt).format('MM-DD-YY') === moment(this.state.createdAt).format('MM-DD-YY');
        })

        if (matchedPoint[0]) {
            this.setState({ 
                id: matchedPoint[0].id,
                chore: matchedPoint[0].chore,
                note: matchedPoint[0].note,
                isDone: true,
                createdAt: matchedPoint[0].day
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.points !== this.props.points) {
            const matchedPoint = this.props.points.filter((point) => {
                return point.note === this.state.note && moment(point.createdAt).format('MM-DD-YY') === moment(this.state.createdAt).format('MM-DD-YY');
            })
     
            if (matchedPoint[0]) {
                this.setState({ 
                    id: matchedPoint[0].id,
                    chore: matchedPoint[0].chore,
                    note: matchedPoint[0].note,
                    isDone: true,
                    createdAt: matchedPoint[0].day
                });
            }
        }
    }

    isDoneChange = (id) => {
        if (this.state.isDone === false) {
            const point = {
                chore: this.state.chore,
                note: this.state.note,
                isDone: true,
                createdAt: this.state.createdAt.valueOf()
            };

            this.props.dispatch(startAddPoint(point));
        } else {
            this.setState({ 
                id: 0,
                chore: this.props.chore.split(' ').join('').toLowerCase(),
                note: this.props.chore,
                isDone: false,
                createdAt: this.props.day
            });
            this.props.dispatch(startDeletePoint({ id }));
        }
    }

    render() {
        return (
            <div className="grid__row__week__point" onClick={ () => { this.isDoneChange(this.state.id) }  } >
                {
                    this.state.isDone ? <FontAwesomeIcon icon={ faCheckCircle } /> : ''
                }
            </div>
        )
    };
};


// export default connect()(ChorePoint);
// export default ChorePoint;
const mapStateToProps = (state) => {
    return {
        points: selectPoints(state.points, state.filters)
    };
};

export default connect(mapStateToProps)(ChorePoint);

// <span onClick={ () => props.dispatch(startEditPoint(props.point.id, { isDone: !props.point.isDone })) } >
//     <FontAwesomeIcon icon={ props.point.isDone ? faCheckCircle : faMinusCircle } /> 
// </span>


                    
// <FontAwesomeIcon icon={ faCheckCircle } />



// isDoneChange = (id, e) => {
//     e.preventDefault();
//     if (this.state.isDone === false) {
//         const point = {
//             chore: this.state.chore,
//             note: this.state.note,
//             isDone: true,
//             createdAt: this.state.createdAt.valueOf()
//         };

//         this.props.dispatch(startAddPoint(point));
//     } else {
//         this.props.dispatch(startDeletePoint({ id }));
//     }
//     // window.location.reload();
// }

// render() {
//     return (
//         <div className="grid__row__week__point" onClick={ console.log('done') } >
//             <form onSubmit={ (e) => { this.isDoneChange(this.state.id, e) } } >
//                 <button className="input-group__item__button">
//                     {
//                         this.state.isDone ? <FontAwesomeIcon icon={ faCheckCircle } /> : ''
//                     }
//                 </button>
//             </form>
//         </div>
//     )
// };