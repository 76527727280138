import React, { Component } from 'react';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';

class AddChoreForm extends Component {
    state = {
        name: this.props.name,
        add: this.props.add,
        error: false,
        isSubmitted: false
    }

    capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    onChoreChange = (e) => {
        const name = e.target.value;
        // const choreNameCapitalized = choreName.split(' ').map((name) => this.capitalize(name)).join(' ');
        // console.log(choreNameCapitalized)

        this.setState({ name });
        this.setState({ isSubmitted: false });
    }

    onFormSubmit = (e) => {
        e.preventDefault();
        // console.log(this.props.points)
        const choreNameCapitalized = this.state.name.split(' ').map((name) => this.capitalize(name)).join(' ');

        // Validate if there is a chore
        if (!this.state.name) {
            // Set error message
            this.setState({ error: true })
        } else {
            this.setState({ error: false })
            this.props.onSubmit({
                name: choreNameCapitalized
            }, this.state.add);
            this.setState({ isSubmitted: true, name: '' });
        }
    }

    render() {
        return (
            <div className="point-form">
                { this.state.error && <p style={{ color: "red", fontWeight: "bolder", textAlign: "center" }}>Please add a Chore</p> }
                { this.state.isSubmitted && <p style={{ color: "green", fontWeight: "bolder", textAlign: "center" }}>Point Submitted</p> }
                <form onSubmit={ this.onFormSubmit }>
                    <div className="input-group">
                        <div className="input-group__item">
                            <input type="text" value={ this.state.name } onChange={ this.onChoreChange }/>
                        </div>
                    </div>
                   
                    <button className="input-group__item__button">Submit</button>
                </form>
            </div>
        )
    }
};

export default AddChoreForm;




// <input 
// type="text"
// placeholder="Description"
// autoFocus
// value={ this.state.description }
// onChange={ this.onDescriptionChange }
// />

// <p>Week { this.state.createdAt.format('gg') }</p>