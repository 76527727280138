//MODULES
import React from 'react';
// CUSTOM FILES
import { currentWeek } from '../../utils/getWeek';
//COMPONENTS
import HeaderDashboardDayOfTheWeek from './HeaderDashboardDayOfTheWeek';

const HeaderDashboardWeek = () => (
    <div className="header__week">
        {
            currentWeek.map((day) => <HeaderDashboardDayOfTheWeek day={ day } key={ day } />)
        }
    </div>
);

export default HeaderDashboardWeek;