import React from 'react';

const StatsWidget = (props) => {
    return (
        <div className="current-week-stats__item">
            <div className="current-week-stats__item__label">{ props.label }</div> 
            <div className="current-week-stats__item__value">{ props.value }</div>
        </div>  
    )
}

export default StatsWidget;