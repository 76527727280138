// MODULES
import moment from 'moment';

// Get current week
let startOfWeek = moment().startOf('isoWeek');
let endOfWeek = moment().endOf('isoWeek');

let currentWeek = [];
let currentWeekNumber = startOfWeek.format('W');

while (startOfWeek <= endOfWeek) {
    currentWeek.push(startOfWeek.toDate());
    startOfWeek = startOfWeek.clone().add(1, 'd');
}
// end get current week

export {
    currentWeek,
    currentWeekNumber
};


// const getWeekNumber =(d) => {
//     // Copy date so don't modify original
//     d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
//     // Set to nearest Thursday: current date + 4 - current day number
//     // Make Sunday's day number 7
//     d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
//     // Get first day of year
//     var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
//     // Calculate full weeks to nearest Thursday
//     var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
//     // Return array of year and week number
//     return weekNo;
// }

// export default getWeekNumber;

