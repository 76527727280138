import database from '../../firebase/firebase';

// ADD_CHORE
export const addChore = (chore) => ({
    type: 'ADD_CHORE',
    chore
});

export const startAddChore = (choreData = {}) => {
    return (dispatch, getState) => {
        const uid = getState().auth.uid;
        const {
            name = '', 
        } = choreData;
        const chore = { name };

        database.ref(`users/${ uid }/chores`).push(chore).then((ref) => {
            dispatch(addChore({
                id: ref.key,
                ...chore
            }));
        });
    };
};


// DELETE CHORE
export const deleteChore = (id) => ({
    type: 'DELETE_CHORE',
    id // change back to id
});

export const startDeleteChore = (id) => {
    return (dispatch, getState) => {
        const uid = getState().auth.uid;
        
        return database.ref(`users/${ uid }/chores/${ id }`)
            .remove()
            .then(() => {
                dispatch(deleteChore(id));
            });
    }
}


// EDIT_CHORES
export const editChore = (id, updates) => ({
    type: 'EDIT_CHORE',
    id,
    updates
});

export const startEditChore = (id, updates) => {
    return (dispatch, getState) => {
        const uid = getState().auth.uid;
        
        return database.ref(`users/${ uid }/chores/${ id }`)
            .update(updates)
            .then(() => {
                dispatch(editChore(id, updates));
                console.log(`Chore #${ id } updated`);
            });
    };
};


// SET_CHORES
export const setChores = (chores) => ({
    type: 'SET_CHORES',
    chores
});

export const startSetChores = () => {
    return (dispatch, getState) => {
        const uid = getState().auth.uid;

        return database.ref(`users/${ uid }/chores`)
            .once('value')
            .then((snapshot) => {
                const chores = [];
                
                snapshot.forEach((childSnapshot) => {
                    chores.push({
                        id: childSnapshot.key,
                        ...childSnapshot.val()
                    })
                });

            dispatch(setChores(chores));
        });
    }
};