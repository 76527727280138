// MODULES
import React from 'react';
import { connect } from 'react-redux';
// CUSTOM FILES
// import { chores } from '../../redux/fixtures/test-data'
// COMPONENTS
import ChoreItem from './ChoreItem';
import ChoreWeek from './ChoreWeek';

const ChoreGrid = ({ chores }) => (
    <div>
        <div>
            Earned this period: 
            { 
                //` $${props.points.length * .25}`  
            }
        </div>
        <div className="grid">
            {
                chores.map((chore) => {
                    return (
                        <div className="grid__row" key={ chore.name }>
                            <ChoreItem chore={ chore.name } />
                            <ChoreWeek chore={ chore.name } />
                        </div>
                    );
                })
                
            }
        </div>
    </div>
);


const mapStateToProps = (state) => {
    return {
        chores: state.chores
    };
};

export default connect(mapStateToProps)(ChoreGrid);