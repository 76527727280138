// import uuid from 'uuid';
import database from '../../firebase/firebase';

// Use UUID for ID or grab from AirTable
// ACTIONS
// ADD_POINTS
export const addPoint = (point) => ({
        type: 'ADD_POINT',
        point
    }
);

export const startAddPoint = (pointData = {}) => {
    return (dispatch, getState) => {
        const uid = getState().auth.uid;
        const {
            note = '', 
            chore = '',
            isDone = false,
            createdAt = 0,
        } = pointData;
        const point = { note, chore, isDone, createdAt };

        database.ref(`users/${ uid }/points`).push(point).then((ref) => {
            dispatch(addPoint({
                id: ref.key,
                ...point
            }));
        });
    };
};

// DELETE POINT
export const deletePoint = ({ id }) => ({
    type: 'DELETE_POINT',
    id
});

export const startDeletePoint = ({ id } = {}) => {
    return (dispatch, getState) => {
        const uid = getState().auth.uid;
        
        return database.ref(`users/${ uid }/points/${ id }`)
            .remove()
            .then(() => {
                dispatch(deletePoint({ id }));
            });
    }
}

// EDIT_POINTS
export const editPoint = (id, updates) => ({
    type: 'EDIT_POINT',
    id,
    updates
});

export const startEditPoint = (id, updates) => {
    return (dispatch, getState) => {
        const uid = getState().auth.uid;
        
        return database.ref(`users/${ uid }/points/${ id }`)
            .update(updates)
            .then(() => {
                dispatch(editPoint(id, updates));
                console.log(`Point #${ id } updated`);
            });
    };
};

// SET_POINTS
export const setPoints = (points) => ({
    type: 'SET_POINTS',
    points
});

export const startSetPoints = () => {
    return (dispatch, getState) => {
        const uid = getState().auth.uid;

        return database.ref(`users/${ uid }/points`)
            .once('value')
            .then((snapshot) => {
                const points = [];
                
                snapshot.forEach((childSnapshot) => {
                    points.push({
                        id: childSnapshot.key,
                        ...childSnapshot.val()
                    })
                });

            dispatch(setPoints(points));
        });
    }
};

// SUBMIT_POINTS