// MODULES
import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';

class PointForm extends Component {
    state = {
        chore: '',
        note: '',
        isDone: false,
        createdAt: moment(),
        calendarFocused: false,
        error: false,
        isSubmitted: false
    }

    onNoteChange = (e) => {
        const note = e.target.value;
        const chore = note.toLowerCase().split(' ').join('');
        this.setState({ note, chore })
        // setTimeout(() => {
        //     console.log(this.state.note, this.state.chore);
        // }, 0);
        this.setState({ isSubmitted: false });
    }

    onDoneChange = (e) => {
        if (this.state.isDone === false) {
            this.setState({ isDone: true });
        } else {
            this.setState({ isDone: false });
        }
    }

    onFormSubmit = (e) => {
        e.preventDefault();
        // console.log(this.props.points)

        // Validate if there is a chore
        if (!this.state.chore) {
            // Set error message
            this.setState({ error: true })
        } else {
            this.setState({ error: false })
            this.props.onSubmit({
                note: this.state.note,
                chore: this.state.chore,
                isDone: this.state.isDone,
                createdAt: this.state.createdAt.valueOf()
            });
            this.setState({ isSubmitted: true });
        }
    }
    
    onDateChange = (createdAt) => {
        // IF prevents user from clearing date
        if (createdAt) {
            this.setState(() => ({ createdAt }));
        }
    }

    grabCurrentDate = () => {
        return this.state.createdAt.format('gg');
    }

    onFocusChange = ({ focused }) => {
        this.setState(() => ({ calendarFocused: focused }));
    }

    render() {
        return (
            <div className="point-form">
                { this.state.error && <p style={{ color: "red", fontWeight: "bolder", textAlign: "center" }}>Please add a Description</p> }
                { this.state.isSubmitted && <p style={{ color: "green", fontWeight: "bolder", textAlign: "center" }}>Point Submitted</p> }
                <form onSubmit={ this.onFormSubmit }>
                    <div className="input-group">
                        <input type="hidden" value={ this.state.chore } />
                        <div className="input-group__item">
                            <select onChange={ this.onNoteChange } className="input-group__item__dropdown">
                                <option>---select---</option>
                                {
                                    this.props.chores.map((chore) => {
                                        return <option value={ chore.name }>{ chore.name }</option>
                                    })
                                }
                            </select>
                        </div>
                        <div className="input-group__item">
                            <SingleDatePicker 
                                date={ this.state.createdAt }
                                onDateChange={ this.onDateChange }
                                focused={ this.state.calendarFocused }
                                onFocusChange={ this.onFocusChange }
                                numberOfMonths={ 1 }
                                isOutsideRange={ () => false }
                            />
                        </div>
                        <div className="input-group__item__checkbox">
                            <input 
                                type="checkbox" 
                                name="isDone" 
                                value={ this.state.isDone } 
                                onChange={ this.onDoneChange } 
                                checked={ this.state.isDone === true ? "checked" : ""}
                            />
                            <div className=".input-group__item__label">
                                <label htmlFor="isDone">Done</label>
                            </div>
                        </div>
                    </div>
                   
                    <button className="input-group__item__button">Submit</button>
                </form>
            </div>
        )
    }
};

const mapStateToProps = (state) => {
    return {
        chores: state.chores
    };
};

export default connect(mapStateToProps)(PointForm);




// <input 
// type="text"
// placeholder="Description"
// autoFocus
// value={ this.state.description }
// onChange={ this.onDescriptionChange }
// />

// <p>Week { this.state.createdAt.format('gg') }</p>


// <option value="Read Book">Read Book</option>
// <option value="Clear Table">Clear Table</option>
// <option value="Piano">Piano</option>
// <option value="Clean Room">Clean Room</option>