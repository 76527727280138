// MODULES
import moment from 'moment';

// Get current week
let startOfWeek = moment().startOf('isoWeek');
let endOfWeek = moment().endOf('isoWeek');

let currentWeek = [];
let day = startOfWeek;

while (day <= endOfWeek) {
    currentWeek.push(day.toDate());
    day = day.clone().add(1, 'd');
}
// end get current week

export {
    currentWeek
};

// const chores = [
//     'Clean Room', 
//     'Clear Table', 
//     'Do Homework', 
//     'Eat Veggies', 
//     'Feed Pet', 
//     'Read Book', 
//     'Piano'
// ];


// export {
//     currentWeek
    // chores
    // completedChores
// };


// const completedChores = [
//     {
//         note: 'Feed Pet',
//         chore: 'feedpet',
//         isDone: true,
//         createdAt: 'Tue'
//     },
//     {
//         note: 'Feed Pet',
//         chore: 'feedpet',
//         isDone: true,
//         createdAt: 'Wed'
//     },
//     {
//         note: 'Feed Pet',
//         chore: 'feedpet',
//         isDone: true,
//         createdAt: 'Thu'
//     },
//     {
//         note: 'Feed Pet',
//         chore: 'feedpet',
//         isDone: true,
//         createdAt: 'Fri'
//     },
//     {
//         note: 'Feed Pet',
//         chore: 'feedpet',
//         isDone: true,
//         createdAt: 'Sun'
//     },
//     {
//         note: 'Clear Table',
//         chore: 'cleartable',
//         isDone: true,
//         createdAt: 'Mon'
//     },
//     {
//         note: 'Clear Table',
//         chore: 'cleartable',
//         isDone: true,
//         createdAt: 'Wed'
//     },
//     {
//         note: 'Clear Table',
//         chore: 'cleartable',
//         isDone: true,
//         createdAt: 'Sat'
//     },
//     {
//         note: 'Do Homework',
//         chore: 'dohomework',
//         isDone: true,
//         createdAt: 'Fri'
//     },
//     {
//         note: 'Eat Veggies',
//         chore: 'eatveggies',
//         isDone: true,
//         createdAt: 'Tue'
//     },
//     {
//         note: 'Eat Veggies',
//         chore: 'eatveggies',
//         isDone: true,
//         createdAt: 'Wed'
//     },
//     {
//         note: 'Eat Veggies',
//         chore: 'eatveggies',
//         isDone: true,
//         createdAt: 'Thu'
//     },
//     {
//         note: 'Read Book',
//         chore: 'readbook',
//         isDone: true,
//         createdAt: 'Thu'
//     },
//     {
//         note: 'Piano',
//         chore: 'piano',
//         isDone: true,
//         createdAt: 'Mon'
//     },
//     {
//         note: 'Piano',
//         chore: 'piano',
//         isDone: true,
//         createdAt: 'Wed'
//     },
//     {
//         note: 'Piano',
//         chore: 'piano',
//         isDone: true,
//         createdAt: 'Fri'
//     }
// ]