// MODULES
import React from 'react';
// COMPONENTS
import HeaderDashboard from './dashboard-calendar-view/HeaderDashboard';
import ChoreGrid from './dashboard-calendar-view/ChoreGrid';

const DashboardCalendarView = () => (
    <div className="app-section-wrapper">
        <div className="dashboard__header__title">Cadence's Points Chart</div>
        <HeaderDashboard />
        <ChoreGrid />
    </div>
)

export default DashboardCalendarView;