//MODULES
import React from 'react';
// CUSTOM FILES
import { currentWeekNumber } from '../../utils/getWeek';

const HeaderDashboardWeekOf = () => (
    <div className="header__week-of">
    Week Number { currentWeekNumber }
    </div>
);

export default HeaderDashboardWeekOf;