// MODULES
import React from 'react';
import { connect } from 'react-redux';
// CUSTOM FILES
import selectPoints from '../../../redux/selectors/selectPoints';

// COMPONENTS
import StatsWidget from './StatsWidget';

const CurrentWeekStats = (props) => {
    return (
        <div className="current-week-stats">
            <StatsWidget 
                label="Week"
                value={ props.weekNumber }
            />

            <StatsWidget 
                label="Cash Available"
                value={ //`$${ props.cashBalance }` }
                    `$${(props.points.length * .25).toFixed(2)}`
                }
            />

            <StatsWidget 
                label="RC/MC Available"
                value={ props.rbmcBalance }
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        points: selectPoints(state.points, state.filters)
    };
};

export default connect(mapStateToProps)(CurrentWeekStats);

//TO DO: Get all points and deductions