const pointsReducerDefaultState = [];

export default (state = pointsReducerDefaultState, action) => {
    switch (action.type) {
        case 'ADD_POINT':
            return [
                ...state, 
                action.point
            ];
        case 'DELETE_POINT':
            return state.filter(({ id }) => id !== action.id);
        case 'EDIT_POINT':
            return state.map((point) => {
                if (point.id === action.id) {
                    return {
                        ...point,
                        ...action.updates
                    }
                } else {
                    return point
                }
            });
        case 'SET_POINTS':
            return action.points;
        default:
            return state;
    }
};