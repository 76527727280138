// MODULES
import React from 'react';
// CUSTOM FILES
// COMPONENTS
import ChoresSettingsList from './ChoresSettingsList';


const ChoreSettingPage = () => (
    <div className="app-section-wrapper">
        <div className="dashboard__header__title">List of Chores</div>
        
        <div className="dashboard-list-view__header">
            <div className="dashboard-list-view__header__description">Chore</div>
            <div className="dashboard-list-view__header__options">Options</div>
        </div>

        <ChoresSettingsList />
    </div>
);

export default ChoreSettingPage;