//MODULES
import React from 'react';
// import { connect } from 'react-redux';
// CUSTOM FILES
import { currentWeek } from '../../redux/fixtures/test-data'
//COMPONENTS
import ChorePoint from './ChorePoint';


const ChoreWeek = (props) => {
    // const matchedPoint = props.points.filter((point) => {
    //     return point.note === props.note && moment(point.createdAt).format('MM-DD-YY') === moment(props.createdAt).format('MM-DD-YY');
    // })

    return (
            <div className="grid__row__week">
                {
                   
                    currentWeek.map((day) => <ChorePoint 
                        chore={ props.chore } day={ day }  
                        key={ props.chore + day } 
                    />)
                }
            </div>
        )
    };


// const mapStateToProps = (state) => {
//     return {
//         chores: state.chores
//     };
// };

// export default connect(mapStateToProps)(ChoreWeek);
export default ChoreWeek;