// MODULES
import React from 'react';
import { connect } from 'react-redux';
import { startAddPoint } from '../../../redux/actions/points';
// COMPONENTS
import PointForm from './PointForm';

const AddPointPage = (props) => {
    return (
        <div className="app-section-wrapper">
            <div className="add-point-form">
                <h3>Add Point Form</h3>
                <PointForm 
                    onSubmit={(point) => {
                        props.dispatch(startAddPoint(point));
                    }}
                />
            </div>
        </div>
    )
};

export default connect()(AddPointPage);