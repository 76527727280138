// MODULES
import React from 'react';
import Modal from 'react-modal';
// CUSTOM FILES
import { startDeleteChore } from '../../redux/actions/chores';
// COMPONENTS
import AddChorePage from './AddChorePage';
// STYLES
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
}

// TO DO
// 1. Add Modal to Add Chore
// 2. Add state to keep track of id
// 3. Add dispatch to retrieve item 
// 4. Add dispatch to edit item
class ChoreSettingsItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modalIsOpen: false,
            isHidden: true, 
            id: props.chore.id,
            name: this.props.chore.name
        };

        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    openModal() {
        this.setState({modalIsOpen: true});
        console.log('CSI: ', this.props)
    }

    afterOpenModal() {
        // references are now sync'd and can be accessed.
        // this.subtitle.style.color = '#f00';
    }

    closeModal() {
        this.setState({modalIsOpen: false});
    }

    handleModal = () => {
        this.setState({ isHidden: !this.state.isHidden });
    }

    render() {
        return (
            <div>
                <div className="dashboard-list-view__points__point">
                    <div className="dashboard-list-view__points__point__description">
                    { this.props.chore.name }
                    </div>
                    <div className="dashboard-list-view__points__point__options">
                        <span onClick={ () => { 
                            this.openModal();
                        } } >
                            <FontAwesomeIcon icon={ faEdit } /> 
                        </span>
                        <span className="delete-point-button" onClick={ () => { 
                            const choreId = this.props.chore.id;
                            this.props.dispatch(startDeleteChore( choreId )) 
                        } } >
                            <FontAwesomeIcon icon={ faTrashAlt } /> 
                        </span>
                    </div>


                    <Modal
                        isOpen={this.state.modalIsOpen}
                        onAfterOpen={this.afterOpenModal}
                        onRequestClose={this.closeModal}
                        style={customStyles}
                        contentLabel="Example Modal"
                    >
                        <button className="add-point-modal__close" onClick={ this.closeModal }>x</button>
                        <AddChorePage name={ this.state.name } add={ false } id={ this.state.id }/>
                    </Modal>
                </div>
            </div>
        );
    };    
};

export default ChoreSettingsItem;