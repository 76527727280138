// MODULES
import React from 'react';
import { connect } from 'react-redux';
// CUSTOM FILES
import selectPoints from '../../../redux/selectors/selectPoints';
// COMPONENTS
import PointItem from './PointItem';

let value = 0.25;

const PointsList = (props) =>(
    <div>
        <div>
            Earned this period: 
            { ` $${props.points.length * value}`  }
        </div>
        <div className="points-list"> 
            { 
                props.points.map((point, index) => {
                    return (
                        <div key={ index }>
                            <PointItem point={ point } dispatch={ props.dispatch } />
                        </div>
                    );
                })
            }
        </div>
    </div>
);

const mapStateToProps = (state) => {
    return {
        points: selectPoints(state.points, state.filters)
    };
};

export default connect(mapStateToProps)(PointsList);

// TODO: Create a new component for each point