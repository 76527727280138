// MODULES 
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
//CSS
import 'react-dates/lib/css/_datepicker.css';
// CUSTOM FILES
import { setTextFilter, setStartDate, setEndDate } from '../../../redux/actions/filters';

class PointsListFilter extends Component {
    state = {
        calendarFocused: null
    }
    
    onDatesChange = ({ startDate, endDate }) => {
        this.props.dispatch(setStartDate(startDate));
        this.props.dispatch(setEndDate(endDate));
    }

    onFocusChange = (calendarFocused) => {
        this.setState(() => ({ calendarFocused }));
    }

    render() {
        return (
            <div className="dashboard-list-view__filter"> 
                <input 
                    type="text" 
                    value={ this.props.filters.text } 
                    onChange={(e) => {
                        const filterValue = e.target.value;
                    this.props.dispatch(setTextFilter(filterValue));
                    }} 
                    className="dashboard-list-view__filter__text"
                    placeholder="Filter Your Points"
                ></input>
                <DateRangePicker
                    startDate={ this.props.filters.startDate }
                    startDateId={ "startDate" }
                    endDate={ this.props.filters.endDate }
                    endDateId={ "endDate" }
                    onDatesChange={ this.onDatesChange }
                    focusedInput={ this.state.calendarFocused }
                    onFocusChange={ this.onFocusChange }
                    showClearDates={ true }
                    numberOfMonths={ 1 }
                    isOutsideRange={ () => false }
                    firstDayOfWeek={ 1 }
                />
            </div>
        )
    }
};

const mapStateToProps = (state) => {
    return {
        filters: state.filters
    }
}

export default connect(mapStateToProps)(PointsListFilter);