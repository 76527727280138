// MODULES
import React from 'react';
import moment from 'moment';
import Modal from 'react-modal';
import { startDeletePoint, startEditPoint } from '../../../redux/actions/points';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
// COMPONENTS
import AddPointPage from '../../dashboard-list-view/add-point-form/AddPointPage';


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
}

class PointItem extends React.Component { 
    constructor() {
        super();

        this.state = {
            modalIsOpen: false,
            isHidden: true, 
            id: ''
        };

        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    openModal(id) {
        this.setState({modalIsOpen: true});
    }

    afterOpenModal() {
        // references are now sync'd and can be accessed.
    }

    closeModal() {
        this.setState({modalIsOpen: false});
    }

    handleModal = () => {
        this.setState({ isHidden: !this.state.isHidden });
    }

    render() {
        return (
            <div className="dashboard-list-view__points__point">
                <div className="dashboard-list-view__points__point__description">
                    { this.props.point.note }
                </div>

                <div className="dashboard-list-view__points__point__date">
                    { moment(this.props.point.createdAt).format('MM/DD/YY')}
                </div>
                
                <div className="dashboard-list-view__points__point__options">
                    <span onClick={ () => { this.openModal(this.props.point.id) } } >
                        <FontAwesomeIcon icon={ faEdit } /> 
                    </span>
                    <span className="delete-point-button" onClick={ () => this.props.dispatch(startDeletePoint({ id: this.props.point.id })) } >
                        <FontAwesomeIcon icon={ faTrashAlt } /> 
                    </span>
                </div>
                    
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <button className="add-point-modal__close" onClick={ this.closeModal }>x</button>
                    <AddPointPage id={ 0 } name={ 'name' } />
                </Modal>
            </div>
        )
    }
}

export default PointItem;


// () => this.props.dispatch(startEditPoint(this.props.point.id, { isDone: !this.props.point.isDone }))