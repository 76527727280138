//MODULES
import React from 'react';
import moment from 'moment';

const HeaderDashboardDayOfTheWeek = ({ day }) => {
    return (
        <div className="header__week__day">
            { moment(day).format('ddd') }
        </div>
    )
};

export default HeaderDashboardDayOfTheWeek;